export default {
	statusLoadingSkeleton: false,
	tableTitle: '',
	singleTable: {},
	allTableData: {},
	singleTableData: {},
	tablesList: [],
	pageKind: 'main',
	xFormStatus: '',
	permissions: {},
	showXActions: false,
	saveBtnDisabled: true,
	changedSettings: [],
	loopKey: Date.now(),
	tableRows: {},
	changeList: {},
	revisionList: {},
	parameterPosition: 1,
	rowPosition: 1,
	tablesListData: [],
	tableParameters: [],
	parameterList: [],
	freeTableData: {},
	freeRenderData: {},
	parameterTitle: '',
	parameterText:'',
	parameterLatex:'',
	parameterUnits: ''
}