import { createApp } from './ssr-entry';

const { app } = createApp({state: window.__INITIAL_STATE__});

import './styles/main.scss';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

app.$mount('#app');