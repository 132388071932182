import Vue from 'vue';
import Meta from 'vue-meta'
import App from './ssr-entry.vue';
import Vuex from 'vuex';
import createStore from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueRellax from 'vue-rellax'

import {cookies} from './libs/cookies'
import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';

require('./services/prototypes');

Vue.use(VueKatex, {
  globalOptions: {
    //... Define globally applied KaTeX options here
  }
});

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Vuex);
Vue.use(Meta, {
    ssrAppId: 1 // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});
Vue.use( VueRellax );


Vue.mixin({
  methods: {
    cookies:cookies
  }
})

export const createApp = (context) =>  {

    const store = createStore(context.state);

    const app = new Vue({
        store,
        render: h => h(App)
    });
    
    return {app, store};
};