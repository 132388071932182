function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
}

function getCookie(c_name) {
    try{
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
                return unescape(y);
            }
        } 
    }catch(e){
        console.log(e);
        return 'no'
    }
}

export const cookies = () => {
    return  {
        display(){
            let cookies = getCookie('cookie-choice')
            if(cookies) return true;
            else return false
        },
        set(functional, analytical , marketing){
            setCookie('cookie-choice', JSON.stringify({
                functional:functional,
                analytical:analytical,
                marketing: marketing
            }))
            location.reload()
        },
        loggedIn(){
            let login = getCookie('login')
            if(login == 'yes') return true;
            else return false
        }
    }
}