import {Store} from 'vuex';
import * as getters from './getters';
import jobs from './modules/jobs';
import menu from './modules/menu';
import tables from './modules/tables';
import calculation from './modules/calculation';
import form from './modules/form';
import table from './modules/table';
import notification from './modules/notification';
import helper from './modules/helper';

const createStore = (state) => {
    return new Store({
        state,
        getters,
        modules: {
            jobs,
            menu,
            tables,
            calculation,
            form,
            table,
            notification,
            helper
        },
        strict: process.env.NODE_ENV !== 'production',
    });
};

export default createStore;