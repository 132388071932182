'use strict';

import axios from "axios";

export default {
	clientRender: ( state, data ) => {
		return new Promise( done => {
			axios.post( '/calculation/client-render', data, { baseURL: '/api/p' } )
				.then( res => { 
					if ( res.data?.success ) state.commit( 'updateCalculationInterface', res.data );
					done( res.data ) 
				} )
				.catch( err => done( err ) )
		} )
	},
	clientExecute: ( state, data ) => {
		return new Promise( done => {
			axios.post( '/calculation/client-execute', data, { baseURL: '/api/p' } )
				.then( res => {
					done( res.data ) 
				} )
				.catch( err => done( err ) )
		} )
	}
}