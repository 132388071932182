<template>
    <div class="generic-blocks-container">
        <block
            v-for="(item, index) in structure"
            :key="index"
            :block="item"
            :data="data"
        ></block>
    </div>
</template>

<script>

import Block from '../components/Block.vue';
// import CSShoppingCard from '../components/CSShoppingCard.vue';

export default {
    name: 'Generic',
    props: {
        data: {},
        structure: {}
    },
    components: {
        Block,
        // CSShoppingCard
    }
}
</script>

<style lang="scss">
    @import "../libs/common-styles.scss";
    @import "../libs/snow.min.css"
</style>