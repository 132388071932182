'use strict';

import axios from "axios";

export default {
	getClientRender: ( state, data ) => {
		let query = '';

		if (data.exportId?.length) {
			query += `?export=${data.exportId}`
		}
		
		return new Promise( done => {
			axios.get( `/table/client-render/${ data.id }${query}`, { baseURL: '/api/p' } )
				.then( res => { 
					state.commit('updateFreeRenderData', res.data?.data);
					done( res.data )
				} )
				.catch( err => done( err ) )
		} )
	},
	getParameterData: ( state, data ) => {
		return new Promise( done => {
			let urlParameter = '';
            if ( data.calc ){
                let urlParameterArray = [];
                if (data.calc ) urlParameterArray.push(`calc=${data.calc}`)
                urlParameter = `?${urlParameterArray.join('&')}`
            }
			axios.get( 
				`/table/calculation-parameter-data/${ data.tableId }/${ data.paramId }${urlParameter}`, 
				{ baseURL: '/api/p' } 
			).then( res => { 
				done( res.data ) 
			} )
			.catch( err => done( err ) )
		} )
	},
}