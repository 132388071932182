<template>
    <component :is="block.component" :data="data[block.dataId]" > 
        <block v-for="(item, index) in block.children" :key="index" :block="item" :data="data"></block>
    </component>
</template>
<script>

export default {
    name: 'Block',
    props: {
        block: {},
        data: {}
    },
    components: {
		BlogPostArchive:() => import( '../blocks/BlogPostArchive.vue' ),
		BlogPostContent:() => import( '../blocks/BlogPostContent.vue' ),
		BlogPostPreview:() => import( '../blocks/BlogPostPreview.vue' ),
		Calculation:() => import( '../blocks/Calculation.vue' ),
		ClientMyOrder:() => import( '../default-blocks/ClientMyOrder.vue' ),
		ContactForm:() => import( '../blocks/ContactForm.vue' ),
		CookieBanner:() => import( '../blocks/CookieBanner.vue' ),
		Embed:() => import( '../blocks/Embed.vue' ),
		FAQ:() => import( '../blocks/FAQ.vue' ),
		FindUs:() => import( '../blocks/FindUs.vue' ),
		FooterMenu:() => import( '../blocks/FooterMenu.vue' ),
		Form:() => import( '../blocks/Form.vue' ),
		HeaderMenu:() => import( '../blocks/HeaderMenu.vue' ),
		HeroSection:() => import( '../blocks/HeroSection.vue' ),
		ImageBlock:() => import( '../blocks/ImageBlock.vue' ),
		ImageSlider:() => import( '../blocks/ImageSlider.vue' ),
		InteractiveContactInterface:() => import( '../blocks/InteractiveContactInterface.vue' ),
		Job:() => import( '../blocks/Job.vue' ),
		LandingPage:() => import( '../blocks/LandingPage.vue' ),
		LogoReel:() => import( '../blocks/LogoReel.vue' ),
		MapBlock:() => import( '../blocks/MapBlock.vue' ),
		MosaicMatrix:() => import( '../blocks/MosaicMatrix.vue' ),
		MosaicMotionModule:() => import( '../blocks/MosaicMotionModule.vue' ),
		MulticolumnText:() => import( '../blocks/MulticolumnText.vue' ),
		NumericalDialogue:() => import( '../blocks/NumericalDialogue.vue' ),
		OrbitalSlider:() => import( '../blocks/OrbitalSlider.vue' ),
		PromoPanel:() => import( '../blocks/PromoPanel.vue' ),
		ShopCategory:() => import( '../default-blocks/ShopCategory.vue' ),
		ShopCheckout:() => import( '../default-blocks/ShopCheckout.vue' ),
		ShopProduct:() => import( '../default-blocks/ShopProduct.vue' ),
		ShopSearch:() => import( '../default-blocks/ShopSearch.vue' ),
		ShowcaseCircles:() => import( '../blocks/ShowcaseCircles.vue' ),
		Table:() => import( '../blocks/Table.vue' ),
		TextBlock:() => import( '../blocks/TextBlock.vue' ),
		Video:() => import( '../blocks/Video.vue' ),
		VisualNarrativeSegment:() => import( '../blocks/VisualNarrativeSegment.vue' )
    },
    methods: {}
}
</script>

<style></style>