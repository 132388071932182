'use strict';

import axios from "axios";

export default {
    getJobs: (context, data) => {
        return new Promise(done => {
            axios.get(`/api/p/jobs/list/${data.tags}/${data.page}`).then(res => {
                if ( res.data && res.data.success ) context.commit('updateJobsList', res.data.jobs);
                
                done(res.data)
            }).catch(err => done(err))
        })
    }
}