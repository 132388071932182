export default {

	addNotification:(context, payload={})=>{
		if ( payload.variant?.length && payload.msg?.length && Object.keys(payload.msg).length ) {

			let labels = {}
			if (payload.labels && typeof payload.labels === 'object' && !Array.isArray(payload.labels) && payload.labels !== null) {
				labels = { ...payload.labels, ...context.state.serverLabels }
			} else {
				labels = context.state.serverLabels
			}

			let notification = {
				msg: []
			}
			for ( let prop in payload ) {
				if ( prop !== 'msg' && Object.hasOwnProperty.call( payload, prop ) ) {
					notification[prop] = payload[prop];
				}
			}

			if ( typeof payload.msg === "string" ) {
				notification.msg.push( payload.msg );
			}
			else if ( Array.isArray(payload.msg) ) {
				notification.msg = payload.msg;
			}
			else if ( typeof payload.msg === "object" && !Array.isArray(payload.msg) && payload.msg !== null ) {
				if ( Object.keys( payload.msg ).length !== 0 ) {
					Object.keys( payload.msg ).forEach( key => {
						notification.msg.push( payload.msg[key] );
					} )
				} else {
					notification.msg.push( Object.values( payload.msg ) );
				}
			}

			notification.msg.forEach( msg => {
				setTimeout( () => {
					if ( msg.includes( '#' ) ) {
						notification.variant = 'warning';
						msg = msg.split( '#' );
						if ( labels[msg[0]] ) {
							notification.msg = `${labels[msg[0]]}: ${msg[1]}`;
							context.commit( 'runNotification', notification );
						} else {
							notification.msg = `${msg[0]} ${msg[1]}`;
							context.commit( 'runNotification', notification );
						}
					} else {
						if ( labels[msg] ) {
							notification.msg = labels[msg];
							context.commit( 'runNotification', notification );
						} else {
							notification.msg = msg;
							context.commit( 'runNotification', notification );
						}
					}
				}, 100 );
			} );
		} else {
			// alert('Failed to add notification, check console for details!');
			// console.log('Failed to add notification, attempt was done with payload:', payload);
		}
	},

}