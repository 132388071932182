'use strict';

const flattenObject = function (ob) {
    var toReturn = {};
    for (const i in ob) {
        if (Object.hasOwnProperty.call(ob, i)) {

            if (((typeof ob[i]) == 'object' && !['{}', '[]'].includes(JSON.stringify(ob[i]))) && ob[i] !== null && !(ob[i] instanceof File)) {
                var flatObject = flattenObject(ob[i]);
                for (const x in flatObject) {
                    if (Object.hasOwnProperty.call(flatObject, x)) toReturn[i + '.' + x] = flatObject[x];
                }
            } else if (['{}', '[]'].includes(JSON.stringify(ob[i]))&& !(ob[i] instanceof File) ) {
                // console.log(ob[i])
                toReturn[i] = JSON.stringify(ob[i]);
            } else {
                toReturn[i] = ob[i];
            }
        }
    }
    return toReturn;
};

const prepare = data => {
    data = flattenObject(data);
    let form = new FormData();
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) form.append(key, data[key]);
    }
    return form;
}

export default prepare;
