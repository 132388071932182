export default {
	updateCalculationInterface: ( state, payload ) => {
		state.calculationInterface = payload
	},

	updateInterfaceVariable: ( state, payload ) => {
		if ( 'variables' in state.calculationInterface && payload.key in state.calculationInterface.variables ) {
			state.calculationInterface.variables[ payload.key ] = {
				...state.calculationInterface.variables[ payload.key ],
				...payload.data,
			}
		}
	},
	
	clearInterfaceVariable: ( state ) => {
		if ( 'variables' in state.calculationInterface ) state.calculationInterface.variables = {};
	}
}