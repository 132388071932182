'use strict';

import axios from "axios";

export default {
    clientRender: ( context, data ) => {
        return new Promise( done => {
            axios.post( '/form/client-render', data, { baseURL: '/api/p' } )
                .then( res =>  done( res.data ) )
                .catch( err => done( err ) )
        } )
    },
    clientSubmit: ( state, data ) => {
        return new Promise( done => {
            axios.post( '/form/client-submit', data, { baseURL: '/api/p' } )
                .then( res => {
                    done( res.data ) 
                } )
                .catch( err => done( err ) )
        } )
    }
}