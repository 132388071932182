'use strict'

const libs = {
    head:[],
    body:[{
        src:'scroll-out.min.js',
        callback:()=>{
            ScrollOut({
            });
        },
        internal:true
    },{
        src:'rellax.min.js',
        callback:()=>{
            //window.rellax = new Rellax('[data-rellax]',{});
        
        },
        internal:true
    }]
}
const add = (location, src, internal, callback) =>{
    var head = document.getElementsByTagName(location)[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    if(callback) script.onload = callback
    if(internal)script.src = '/ssr/libs/'+src;
    head.appendChild(script);
}

export default () => {

    for (const key in libs) {
        if (Object.hasOwnProperty.call(libs, key)) {
            for (let i = 0; i < libs[key].length; i++) {
                add(key, libs[key][i].src, libs[key][i].internal, libs[key][i].callback)
            }
        }
    }
}