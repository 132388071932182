'use strict';

import axios from "axios";

export default {
    tableRetrieve:(context, id) => {
        return new Promise(done => {
            axios.get(`/api/p/table/client-render/${id}`).then(res => {
                if (res.data.success) {
                    done(res.data);
                }
            }).catch(err => {
                done(err);
            });
        });
    }
}