import Vue from 'vue'

Vue.prototype.$eventBus = new Vue();

import katex from 'katex';
Vue.prototype.$katex = katex;

import prepareForm from './prepare-formdata';
Vue.prototype.$prepareForm = prepareForm;

Vue.prototype.$clone = ( value ) => JSON.parse(JSON.stringify(value))

Vue.prototype.$capitalize = ( value ) => value.charAt(0).toUpperCase() + value.slice(1);

Vue.prototype.$isObject = ( value ) => {
  if ( value && value !== null && typeof value === 'object' && !Array.isArray(value) ) return true;
  return false;
};