<template>
    <div id="app" class="content">
        <div class="frontend-wrapper">
            <Generic :data="structure.data" :structure="structure.structure" />
        </div>
    </div>
</template>

<script>

    import Generic from './views/Generic.vue';
    import {mapGetters} from 'vuex';

    import externalLibs from './external-libs'

    export default {
        name: 'App',
        components:{
            Generic
        },
        data(){
            return {
                response: {}
            }
        },
        computed: {
            ...mapGetters({
                structure: 'structure',
            })
        },
        metaInfo: {
            meta: [
                { charset: 'utf-8' },
            ]
        },
        mounted(){
            externalLibs()
        }
    };
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;

    >div:nth-child(2) {
        min-height: 100vh;
    }
}

.footer {
    height: 24px;
    line-height: 24px;
    text-align: right;
    background: #ddd;
}
</style>
