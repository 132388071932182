'use strict';

import axios from "axios";

export default {
    retrieve:(context, data) => {
        return new Promise(done => {
            if(data.id) done(Object.values(data.id))
            else done([])
        });
    }
}