export default {

    runNotification: ( state, payload )=> {
        state.notifications = { ...payload }
    },

    clearNotification:(state)=> state.notifications = {},

    removeSingleNotification:(state,payload={})=>{
        let index = payload.index;
        state.notifications.splice(index,1)
    },

    updateServerLabels: (state, payload) => state.serverLabels = payload

}